import BigNumber from 'bignumber.js'
import { default as _filterObject } from 'just-filter-object'

/* eslint-disable @typescript-eslint/no-var-requires */
const justIsEmpty = require('just-is-empty')
const justTypeOf = require('just-typeof')

/** 空オブジェクトの判定 */
export const isEmpty = (obj: any): obj is null | undefined => {
  // 数値の場合は空ではないとみなす
  if (justTypeOf(obj) === 'number') {
    return false
  }

  // booleanの場合はからではないとみなす
  if (justTypeOf(obj) === 'boolean') {
    return false
  }

  return justIsEmpty(obj)
}

/** 空ではないオブジェクトの判定 */
export const isNotEmpty = <T>(obj: T): obj is NonNullable<T> => {
  return !isEmpty(obj)
}

export const filterObject = _filterObject

/** objectからnullableなpropertyを取り除く */
export const omitNullableProperty = <T>(obj: T) => {
  //nullableなpropertyを取り除く
  const filteredObject = filterObject(obj as any, (key, value) =>
    isNotEmpty(value)
  )

  //型定義上はすべてのpropertyを必須にする
  return filteredObject as Required<T>
}

/** 半角英数字を全角に変換 */
export const replaceHalfStringToFull = (str: string) => {
  return str.replace(/[!-~]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0)
  })
}

/** テキストが半角数字かどうかの判定 */
export const isHalfNumber = (str: string) => {
  const regex = /^[0-9\b]+$/

  return str === '' || regex.test(str)
}

/** テキストが半角数字あるいは全角数字かどうかの判定 */
export const isNumber = (str: string) => {
  const regex = /^[0123456789０１２３４５６７８９\b]+$/

  return str === '' || regex.test(str)
}

export const calculateTax = (
  taxIncludedPrice: number,
  taxRateNumber: number
): number =>
  Math.floor(
    new BigNumber(taxIncludedPrice)
      .times(taxRateNumber)
      .div(new BigNumber(100).plus(taxRateNumber))
      .toNumber()
  )

/** 処理の停止 */
export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, ms)
  })
}

/** arrayBufferをbase64に変換する */
export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  bytes.forEach((byte) => {
    binary += String.fromCharCode(byte)
  })
  return window.btoa(binary)
}

/** 空関数 */
export const noop = () => {
  return
}

/** 文字数カウンター。全角で2文字、半角で1文字 */
export const countString = ({ str }: { str: string }) => {
  let len = 0

  for (let i = 0; i < str.length; i++) {
    str[i]?.match(/[ -~]/) ? (len += 1) : (len += 2)
  }

  return len
}

/** max以上の文字を省略表記へ。全角で2文字、半角で1文字 */
export const omitString = ({
  str,
  max,
  ellipsis = ' ....',
}: {
  str: string
  max: number
  ellipsis?: string
}) => {
  const kstr = str
  const count = countString({ str: kstr })

  if (count > max) {
    return kstr.slice(0, max / 2) + ellipsis
  } else {
    return kstr
  }
}
