import { DateTime, Interval } from 'luxon'

type YYYY = string
type MM = string
type DD = string

/** YYYY-MM */
export type MonthString = `${YYYY}-${MM}`
/** YYYY-MM-DD */
export type DateString = `${YYYY}-${MM}-${DD}`

export type DateRange = { from: DateString; to: DateString }

export type DateTimeDuring = {
  started_at: DateTimeInput
  ended_at: DateTimeInput
}

export type DateTimeInput = DateTime | string | null | Date

const parse = (at: unknown) => {
  if (DateTime.isDateTime(at)) {
    return at
  }

  if (at instanceof Date) {
    return DateTime.fromJSDate(at)
  }

  if (typeof at === 'string') {
    return DateTime.fromISO(at)
  }

  const invalidReason = `cannot parse : ${JSON.stringify(at)}`

  console.debug(invalidReason)
  return DateTime.invalid(invalidReason)
}

export const parseDateTime = parse

export const dateToAge = (at: DateTimeInput) => {
  const start = parse(at).toFormat('yyyy-LL-dd')
  const end = DateTime.now().toFormat('yyyy-LL-dd')

  const interval = Interval.fromDateTimes(parse(start), parse(end))

  if (!interval.isValid) {
    return 0
  }

  return Math.floor(interval.length('years'))
}

export const dateToLocaleDate = (at: DateTimeInput) => {
  const target = parse(at)

  if (!target.isValid) {
    return 'invalid'
  }

  return target.toFormat('yyyy年LL月dd日')
}

export const dateToLocaleDateWithDay = (
  at: DateTimeInput,
  options = {
    withoutTime: false,
  }
) => {
  const target = parse(at)

  if (!target.isValid) {
    return 'invalid'
  }

  const format = `yyyy年LL月dd日(EEE)${options.withoutTime ? '' : ' HH:mm'}`

  return target.setLocale('ja').toFormat(format)
}

/** 対象の月初日、月末日を返す */
export const dateToMonthStartAndEndDate = (at: DateTimeInput) => {
  const target = parse(at)

  const startDate = target.startOf('month').toFormat('yyyy-LL-dd') as DateString
  const endDate = target.endOf('month').toFormat('yyyy-LL-dd') as DateString

  return { startDate, endDate } as const
}

/** yyyy年 */
export const dateToYear = (at: DateTimeInput) => {
  const target = parse(at)

  if (!target.isValid) {
    return 'invalid'
  }

  return target.toFormat('yyyy年')
}

/** LL月dd日(EEE) */
export const dateToMonthAndDay = (at: DateTimeInput) => {
  const target = parse(at)

  if (!target.isValid) {
    return 'invalid'
  }

  return target.setLocale('ja').toFormat('LL月dd日(EEE)')
}

/** HH:MM */
export const dateToTime = (at: DateTimeInput) => {
  const target = parse(at)

  if (!target.isValid) {
    return 'invalid'
  }

  return target.toFormat('HH:mm')
}

/** HH:mm~HH:mm 表記のstringを返す */
export const duringToTimeRangeString = ({
  started_at,
  ended_at,
}: DateTimeDuring) => {
  return `${dateToTime(started_at)}~${dateToTime(ended_at)}`
}

/** yyyy年LL月dd日(EEE) HH:mm~HH:mm 表記のstringを返す */
export const duringToDateTimeRangeStringWithDay = (during: DateTimeDuring) => {
  return `${dateToLocaleDateWithDay(during.started_at, {
    withoutTime: true,
  })} ${duringToTimeRangeString(during)}`
}

/**
 * from,toの日付期間を、maxDurationのサイズ毎に分割して返す
 * ex. 以下の条件だと4件に分割される
 *  from 2021-09-01
 *  to   2021-09-10
 *  maxDuration 3
 *  => [2021-09-01, 2021-09-02, 2021-09-03], [2021-09-04, 2021-09-05, 2021-09-06], [2021-09-07, 2021-09-08, 2021-09-09], [2021-09-10]
 * */
export const splitDatesFromDateRange = ({
  from,
  to,
  maxDuration,
}: {
  from: NonNullable<DateTimeInput>
  to: NonNullable<DateTimeInput>
  maxDuration: number
}) => {
  //DateTimeにする
  const fromDate = parse(from)
  const toDate = parse(to)

  //分割数を取得
  const diffDays = toDate.diff(fromDate, 'days').days
  const splittingCount = Math.ceil(diffDays / maxDuration)

  //分割する
  const dates = Array(splittingCount)
    .fill('')
    .map((_, index) => {
      const start = fromDate.plus({ days: index * maxDuration })
      const end = start.plus({ days: maxDuration - 1 })
      const isEndGreaterThanTo = toDate < end

      const dateRange: DateRange = {
        from: parseDateString(start),
        //日付がパラメータのtoを超えないようにする
        to: isEndGreaterThanTo ? parseDateString(to) : parseDateString(end),
      }
      return dateRange
    })

  return dates
}

/** 対象月の日付を全て返す */
export const createDatesFromMonth = (at: DateTimeInput) => {
  const target = parse(at)
  const startDate = target.startOf('month')

  const dates = Array(target.daysInMonth)
    .fill('')
    .map(
      (_, index) =>
        startDate.plus({ days: index }).toFormat('yyyy-LL-dd') as DateString
    )

  return dates
}

/** YYYY-MM-DDの日付文字列に変換する */
export const parseDateString = (at: DateTimeInput) => {
  const target = parse(at)

  return target.toFormat('yyyy-LL-dd') as DateString
}

/** YYYY-MMの日付文字列に変換する */
export const parseMonthString = (at: DateTimeInput) => {
  const target = parse(at)

  return target.toFormat('yyyy-LL') as MonthString
}

/** HH:mmの時間文字列に変換する */
export const parseTimeString = (at: DateTimeInput) => {
  const target = parse(at)

  if (!target.isValid) {
    return 'invalid'
  }

  return target.toFormat('HH:mm')
}

/** LL/ddの日付字列に変換する */
export const dateToMonthDay = (at: DateTimeInput) => {
  const target = parse(at)

  if (!target.isValid) {
    return 'invalid'
  }

  return target.toFormat('LL/dd')
}
