import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { atom, useRecoilCallback, useRecoilValueLoadable } from 'recoil'
import { ParsedUrlQuery } from 'querystring'
import { createMemoizedSelector } from '@/lib/recoil/shorthands/createMemoizedSelector'
import { dynamicPathState } from '@/features/states/url/dynamicPathState/dynamicPathState'
import { isNotEmpty } from '@/lib/utility'
import AppBackDrop from '@/components/organisms/AppBackDrop/AppBackDrop'

type Props = {
  children: React.ReactNode
}

const createUrlStore = () => {
  const queryState = atom<ParsedUrlQuery>({
    key: 'queryState',
  })

  const memoizedQueryState = createMemoizedSelector(queryState)

  const useExtendedRouter = () => {
    const router = useRouter()
    const dynamicPath = useRecoilValueLoadable(dynamicPathState)

    return {
      ...router,

      error: dynamicPath.state === 'hasError',
      isReady:
        dynamicPath.state === 'hasValue' &&
        isNotEmpty(dynamicPath.getValue().tenantName) &&
        router.isReady,

      setState: useRecoilCallback(
        ({ set }) =>
          (newQuery: ParsedUrlQuery) => {
            set(queryState, newQuery)
          },
        []
      ),

      resetState: useRecoilCallback(
        ({ reset }) =>
          () => {
            reset(queryState)
          },
        []
      ),
    }
  }

  return {
    queryState,
    memoizedQueryState,
    useExtendedRouter,
  }
}

const urlStore = createUrlStore()

export const { queryState, memoizedQueryState } = urlStore

export const RecoilQueryStateSync = (props: Props) => {
  const { query, error, isReady, setState, resetState } =
    urlStore.useExtendedRouter()

  useEffect(() => {
    setState(query)

    return () => {
      resetState()
    }
  }, [query, setState, resetState])

  if (error) {
    throw new Error('something happend in loading')
  }

  if (!isReady) {
    return <AppBackDrop forceOpen />
  }

  return <>{props.children}</>
}
