import { memoizedQueryState } from '@/features/states/url/queryState'
import { pagesPath } from '@/lib/$path'
import { isEmpty } from '@/lib/utility'
import { RecoilLoadable, selector } from 'recoil'

export type DynamicPaths = 'tenantName' | 'customerId'

export type DynamicPathValue = {
  [K in DynamicPaths]: string
}

export const dynamicPathState = selector<DynamicPathValue>({
  key: 'dynamicPathState',
  get: ({ get }) => {
    const tenantName = String(get(memoizedQueryState('tenantName')) || '')
    const customerId = String(get(memoizedQueryState('customerId')) || '')

    // URLにテナント名は必須
    if (isEmpty(tenantName)) {
      return RecoilLoadable.loading()
    }

    return {
      tenantName,
      customerId,
    }
  },
})

export const tenantRootPathState = selector({
  key: 'tenantRootPathState',
  get: ({ get }) => {
    const { tenantName } = get(dynamicPathState)

    return pagesPath._tenantName(tenantName)
  },
})
