import { RecoilState, selector, selectorFamily } from 'recoil'
import { SelectorProxy } from '../types'
import { get as safeGet } from 'lodash'
import { FieldValues } from 'react-hook-form'

/**
 * Recoilでは、selectorの依存元がobjectの場合は該当object全体が再計算対象となる
 * そのため依存元のobjectから特定propertyのみだけを利用したい場合でもobjectの一部分が変更されたら全て再計算される
 *     - これが、API呼び出しが関わるとパフォーマンス上無視できない問題となってしまう
 * 上記の問題を解決するutilityで、依存元のnodeへproxyを作り特定propertyへのアクセスをカプセル化する
 */
export const createMemoizedSelector = <T extends FieldValues = FieldValues>(
  target: RecoilState<T>
): SelectorProxy<T> => {
  const proxy = selector({
    key: `${target.key}/memoized`,
    get: ({ get }) => {
      const obj = get(target)

      return JSON.stringify(obj)
    },
  })

  // 中間selectorへ、特定プロパティのみに絞って参照するようにすることで他のプロパティの更新が再計算の対象とならないように
  return selectorFamily({
    key: `${proxy.key}/getter`,
    get:
      (property) =>
      ({ get }) => {
        const obj = JSON.parse(get(proxy))

        return safeGet(obj, property)
      },
  })
}
