/* eslint-disable */
// prettier-ignore
import { OptionalQuery as OptionalQuery0 } from '../pages/[tenantName]/customers'
// prettier-ignore
import { OptionalQuery as OptionalQuery1 } from '../pages/[tenantName]/customers/[customerId]'
// prettier-ignore
import { OptionalQuery as OptionalQuery2 } from '../pages/[tenantName]/masters'
// prettier-ignore
import { Query as Query3 } from '../pages/[tenantName]/password'
// prettier-ignore
import { Query as Query4 } from '../pages/[tenantName]/receipt'
// prettier-ignore
import { OptionalQuery as OptionalQuery5 } from '../pages/[tenantName]/reports/business'
// prettier-ignore
import { OptionalQuery as OptionalQuery6 } from '../pages/[tenantName]/reports/contract'
// prettier-ignore
import { OptionalQuery as OptionalQuery7 } from '../pages/[tenantName]/reports/enquete'
// prettier-ignore
import { OptionalQuery as OptionalQuery8 } from '../pages/[tenantName]/reports/enquete/[enqueteId]'
// prettier-ignore
import { OptionalQuery as OptionalQuery9 } from '../pages/[tenantName]/reports/reservations'
// prettier-ignore
import { OptionalQuery as OptionalQuery10 } from '../pages/[tenantName]/reports/sales'
// prettier-ignore
import { OptionalQuery as OptionalQuery11 } from '../pages/[tenantName]/reports/staff'
// prettier-ignore
import { OptionalQuery as OptionalQuery12 } from '../pages/[tenantName]/reports/staff/[staffId]'
// prettier-ignore
import { OptionalQuery as OptionalQuery13 } from '../pages/[tenantName]/reservations'
// prettier-ignore
import { OptionalQuery as OptionalQuery14 } from '../pages/[tenantName]/settings'
// prettier-ignore
import { OptionalQuery as OptionalQuery15 } from '../pages/[tenantName]/shops'

// prettier-ignore
export const pagesPath = {
  _tenantName: (tenantName: string | number) => ({
    _slug: (slug: string[]) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/[tenantName]/[...slug]' as const, query: { tenantName, slug }, hash: url?.hash })
    }),
    customers: {
      _customerId: (customerId: string | number) => ({
        $url: (url?: { query?: OptionalQuery1, hash?: string }) => ({ pathname: '/[tenantName]/customers/[customerId]' as const, query: { tenantName, customerId, ...url?.query }, hash: url?.hash })
      }),
      $url: (url?: { query?: OptionalQuery0, hash?: string }) => ({ pathname: '/[tenantName]/customers' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
    },
    login: {
      $url: (url?: { hash?: string }) => ({ pathname: '/[tenantName]/login' as const, query: { tenantName }, hash: url?.hash })
    },
    maintenance: {
      $url: (url?: { hash?: string }) => ({ pathname: '/[tenantName]/maintenance' as const, query: { tenantName }, hash: url?.hash })
    },
    masters: {
      $url: (url?: { query?: OptionalQuery2, hash?: string }) => ({ pathname: '/[tenantName]/masters' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
    },
    not_allowed_error: {
      $url: (url?: { hash?: string }) => ({ pathname: '/[tenantName]/not_allowed_error' as const, query: { tenantName }, hash: url?.hash })
    },
    password: {
      $url: (url: { query: Query3, hash?: string }) => ({ pathname: '/[tenantName]/password' as const, query: { tenantName, ...url.query }, hash: url.hash })
    },
    permission_error: {
      $url: (url?: { hash?: string }) => ({ pathname: '/[tenantName]/permission_error' as const, query: { tenantName }, hash: url?.hash })
    },
    receipt: {
      $url: (url: { query: Query4, hash?: string }) => ({ pathname: '/[tenantName]/receipt' as const, query: { tenantName, ...url.query }, hash: url.hash })
    },
    reports: {
      business: {
        $url: (url?: { query?: OptionalQuery5, hash?: string }) => ({ pathname: '/[tenantName]/reports/business' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
      },
      contract: {
        $url: (url?: { query?: OptionalQuery6, hash?: string }) => ({ pathname: '/[tenantName]/reports/contract' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
      },
      enquete: {
        _enqueteId: (enqueteId: string | number) => ({
          $url: (url?: { query?: OptionalQuery8, hash?: string }) => ({ pathname: '/[tenantName]/reports/enquete/[enqueteId]' as const, query: { tenantName, enqueteId, ...url?.query }, hash: url?.hash })
        }),
        $url: (url?: { query?: OptionalQuery7, hash?: string }) => ({ pathname: '/[tenantName]/reports/enquete' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
      },
      reservations: {
        $url: (url?: { query?: OptionalQuery9, hash?: string }) => ({ pathname: '/[tenantName]/reports/reservations' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
      },
      sales: {
        $url: (url?: { query?: OptionalQuery10, hash?: string }) => ({ pathname: '/[tenantName]/reports/sales' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
      },
      staff: {
        _staffId: (staffId: string | number) => ({
          $url: (url?: { query?: OptionalQuery12, hash?: string }) => ({ pathname: '/[tenantName]/reports/staff/[staffId]' as const, query: { tenantName, staffId, ...url?.query }, hash: url?.hash })
        }),
        $url: (url?: { query?: OptionalQuery11, hash?: string }) => ({ pathname: '/[tenantName]/reports/staff' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
      }
    },
    reservations: {
      $url: (url?: { query?: OptionalQuery13, hash?: string }) => ({ pathname: '/[tenantName]/reservations' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
    },
    settings: {
      $url: (url?: { query?: OptionalQuery14, hash?: string }) => ({ pathname: '/[tenantName]/settings' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
    },
    shops: {
      $url: (url?: { query?: OptionalQuery15, hash?: string }) => ({ pathname: '/[tenantName]/shops' as const, query: { tenantName, ...url?.query }, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/[tenantName]' as const, query: { tenantName }, hash: url?.hash })
  }),
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

// prettier-ignore
export type PagesPath = typeof pagesPath
