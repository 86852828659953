import { atom, selector } from 'recoil'

export const requestCountState = atom({
  key: 'requestCountState',
  default: 0,
})

export const isRequestingState = selector({
  key: 'isRequesting',
  get: ({ get }) => {
    const requestCount = get(requestCountState)
    return requestCount > 0
  },
})
