import { useRecoilValue } from 'recoil'
import { dynamicPathState, tenantRootPathState } from './dynamicPathState'

export const useTenantRootPath = () => {
  return useRecoilValue(tenantRootPathState)
}

export const useDynamicPath = () => {
  return useRecoilValue(dynamicPathState)
}
