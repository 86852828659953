import Spinner from '@/components/molecules/Loading/Spinner/Spinner'
import * as Mui from '@material-ui/core'
import React, { useMemo } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { useBackDrop } from '@/features/states/apiClient/interceptor/interceptorQueries'

type AppBackDropProps = {
  forceOpen?: boolean
}

/**
 * https://mui.com/customization/default-theme/?expand-path=$.zIndex
 * 他のオーバーレイコンポーネントよりも大きいz-indexを指定する
 * */
const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
    },
  })
)

/** 画面全体をロックしてスピナーを表示する */
export default function AppBackDrop({ forceOpen = false }: AppBackDropProps) {
  const classes = useStyles()
  const shouldOpen = useBackDrop()

  const open = useMemo(() => {
    return forceOpen || shouldOpen
  }, [forceOpen, shouldOpen])

  return (
    <Mui.Backdrop className={classes.backdrop} open={open}>
      <Spinner />
    </Mui.Backdrop>
  )
}
