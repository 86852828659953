import { selector } from 'recoil'
import {
  memoizedQueryState,
  queryState,
} from '@/features/states/url/queryState/RecoilQueryStateSync'
import { isEmpty } from '@/lib/utility'
import { parseDateTime } from '@/lib/datetime'
import { DateTime } from 'luxon'

/**
 * DateTime型にparse可能なqueryパラメータを期待するselectorを作成する
 */
export const createDateTimeSelectorFromQuery = (key: string) => {
  return selector({
    key: `${queryState.key}/${key}`,
    get: ({ get }) => {
      const target = get(memoizedQueryState(key))

      if (isEmpty(target) || isEmpty(target) || typeof target !== 'string') {
        return DateTime.invalid(
          `cannot parse from query parameter ${key}:${target}`
        )
      }

      return parseDateTime(target)
    },
  })
}
